





















import { State } from '@/mixins/state'
import Component, { mixins } from 'vue-class-component'
import Content from '@/components/Content.vue'
import Logo from '@/components/Layout/Logo.vue'
import AlertInfo from '@/components/Global/Alert/AlertInfo.vue'
import { MaintenanceApi } from '@/api'
@Component({
  components: {
    Content,
    Logo,
    AlertInfo
  }
})
export default class UnderConstruction extends mixins(State) {
  private api = new MaintenanceApi()

  public maintenanceTitle = ''
  public maintenanceText = ''

  public async getMaintenanceText () {
    const { data } = await this.api.maintenanceGetMaintenanceText()
    this.maintenanceTitle = data.title ?? ''
    this.maintenanceText = data.content ?? ''
  }

  mounted () {
    this.getMaintenanceText()
  }
}
